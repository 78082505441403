import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, LinearProgress, Paper, TextField } from "@mui/material";

type SearchHeaderProps = {
  query: string;
  setQuery: (query: string) => void;
  isLoading: boolean;
  disabled?: boolean;
};

export const SearchHeader = ({ query, setQuery, isLoading, disabled }: SearchHeaderProps) => (
  <Paper
    elevation={0}
    sx={{
      px: 3,
      pb: 2,
      position: "relative",
    }}
  >
    <TextField
      variant="outlined"
      value={query}
      onChange={({ target: { value } }) => {
        setQuery(value);
      }}
      fullWidth
      autoFocus
      placeholder="Search actions"
      disabled={disabled}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {query && (
                <IconButton
                  onClick={() => {
                    setQuery("");
                  }}
                  size="small"
                  disabled={disabled}
                >
                  <ClearIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        },
      }}
    />
    {isLoading && <LinearProgress sx={{ position: "absolute", left: 0, bottom: 0, right: 0 }} />}
  </Paper>
);
