import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Stack } from "@mui/material";

import { ActionSearchServiceFilter } from "./ActionSearchServiceFilter";
import { type ProviderFilter, type ServiceFilter } from "./hooks/useActionSearchDialogStateMachine";

type ServiceNavigationProps = {
  provider: ProviderFilter;
  service: ServiceFilter | undefined;
  onServiceChange: (service: ServiceFilter | undefined) => void;
  onBackClick: () => void;
};

export const ServiceNavigation = ({ provider, service, onServiceChange, onBackClick }: ServiceNavigationProps) => (
  <Stack direction="row" spacing={2} justifyContent="space-between" pt={1} height={48} flexShrink={0}>
    <Button startIcon={<ArrowBackIcon />} onClick={onBackClick}>
      Back to {provider.name} services
    </Button>
    <ActionSearchServiceFilter
      emptyLabel={`All ${provider.name} services`}
      provider={provider}
      value={service}
      onChange={onServiceChange}
    />
  </Stack>
);
