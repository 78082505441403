import {
  AnomalousOther,
  ExpectedCostSpike,
  FaultyAnomalyDetectionModel,
  IncorrectData,
  LowImpactAnomaly,
  Misconfiguration,
  NonAnomalousOther,
  SecurityBreach,
  UnintentionalProvisioning,
} from "@doitintl/cmp-models";

export const dataSeriesLabels = {
  costAtTimeOfDetection: "Cost at time of detection",
  costSinceTimeOfDetection: "Cost since time of detection",
  adjustmentSinceTimeOfDetection: "Cost adjustment since time of detection",
  normalRange: "Normal range",
};

export const anomalyFeedbackReasonsLabels = {
  [FaultyAnomalyDetectionModel]: "Faulty anomaly detection model",
  [IncorrectData]: "Incorrect data",
  [ExpectedCostSpike]: "Expected cost spike",
  [LowImpactAnomaly]: "Low impact anomaly",
  [NonAnomalousOther]: "Other",
  [SecurityBreach]: "Security breach",
  [Misconfiguration]: "Misconfiguration",
  [UnintentionalProvisioning]: "Unintentional provisioning of services",
  [AnomalousOther]: "Other",
};
