import "react-virtualized/styles.css";

import { type HTMLAttributes, type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Metadata, Positions } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  type AutocompleteRenderInputParams,
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { type PopoverProps } from "@mui/material/Popover/Popover";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import groupBy from "lodash/groupBy";
import remove from "lodash/remove";

import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import { useCloudAnalyticsTransforms } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { type MetadataOption } from "../../../../types";
import { type KeyTypeValues } from "../../api";
import { useReportConfig } from "../../Context";
import { ReportConfigKind } from "../../reducers/ReportConfigReducer";
import {
  createFilterOptions,
  eksClusterNameLabel,
  getTypeString,
  labelTagList,
  limitResultsIds,
  processOptionGroups,
  type ReportAddDimension,
} from "../../utilities";
import { FilterSectionItem } from "./FilterSectionItem";
import { useDebounce } from "./useDebounce";

type Props = {
  metadata: MetadataOption[];
  excludeSelectMetadataIds?: Set<string>;
  position: Positions;
  handleAddDimension: ReportAddDimension;
  onClose: () => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  isOptionDisabled?: (option: MetadataOption) => boolean;
  open: boolean;
};

const FilterContent = ({
  metadata,
  excludeSelectMetadataIds,
  position,
  handleAddDimension,
  onClose,
  handleChangeLabels,
  isOptionDisabled,
}: Props) => {
  const [val, setVal] = useState<string | MetadataOption | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  const {
    reportConfig: { showValuesInFilterSearchResults },
    dispatchReportConfig,
  } = useReportConfig();

  const transforms = useCloudAnalyticsTransforms();

  const checkEksCluster = (option: MetadataOption, labelType: string) =>
    option.data.key === eksClusterNameLabel && option.data.type === labelType;

  const allOptions = useMemo(() => {
    const filtered = metadata.filter((md) => {
      if (md.data.type === Metadata.DATETIME) {
        return md._available;
      }
      return !limitResultsIds.includes(md.id) && !excludeSelectMetadataIds?.has(md.id);
    });

    const checkClusterNameInLabels = filtered.find((option) => checkEksCluster(option, Metadata.LABEL));
    const checkClusterNameInSystemLabels = filtered.find((option) => checkEksCluster(option, Metadata.SYSTEM_LABEL));

    if (checkClusterNameInLabels && checkClusterNameInSystemLabels) {
      remove(filtered, (option) => checkEksCluster(option, Metadata.LABEL));
    }

    const includeRecent = inputValue === "";
    filtered.forEach((md) => {
      md.typeLabel = getTypeString(md, includeRecent);
    });
    return processOptionGroups(filtered, position, undefined, includeRecent);
  }, [excludeSelectMetadataIds, inputValue, metadata, position]);

  const sections = useMemo(() => {
    const grouped = groupBy(allOptions, (option) => getTypeString(option, inputValue === ""));
    const res: { name: string; filtersNum: number }[] = [];

    for (const [key, value] of Object.entries(grouped)) {
      res.push({ name: key, filtersNum: value.length });
    }
    return res;
  }, [allOptions, inputValue]);

  const [selectedSections, setSelectedSections] = useState(sections.map((section) => section.name));

  const handleShowValuesChange = useCallback(
    (checked: boolean) => {
      dispatchReportConfig({
        type: ReportConfigKind.SET_SHOW_VALUES_IN_FILTER_RESULTS,
        payload: { showValuesInFilterSearchResults: checked },
      });
    },
    [dispatchReportConfig]
  );

  const handleOptionSelect = useCallback(
    (option: MetadataOption, filterExistingChip: boolean) => {
      const optionDataType = option?.data?.type;
      // checking if values exists to avoid multiple server requests
      if (optionDataType && labelTagList.includes(optionDataType) && !option.data.values?.length) {
        const values = [
          {
            key: option.data.key,
            type: option.data.type,
          },
        ];
        handleChangeLabels(values, position);
      } else {
        handleAddDimension([option], true, true, true, filterExistingChip)(null);
      }
      setVal(null);
      if (!option.selectedValue) {
        setInputValue("");
      }
    },
    [handleChangeLabels, handleAddDimension, position]
  );

  const handleInputChange = useCallback((value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== "reset") {
      setInputValue(value);
    }
  }, []);

  useEffect(() => {
    if (inputValue !== "") {
      setSelectedSections((prev) => prev.filter((section) => section !== "Recent"));
    } else {
      setSelectedSections(sections.map((section) => section.name));
    }
  }, [inputValue, sections]);

  const getOptionDisabled = useCallback(
    (option: MetadataOption) => {
      if (option.selectedValue) {
        return (option._filter ?? []).includes(option.selectedValue);
      }
      return isOptionDisabled?.(option) || option._disabled || (option._visible && option._position === position);
    },
    [isOptionDisabled, position]
  );

  const PaperComponent = useCallback(
    (props: HTMLAttributes<HTMLElement>) => <Box {...props} sx={{ boxShadow: "none", height: "302px" }} />,
    []
  );

  const handleClickRow = (option: MetadataOption) => {
    if (position === Positions.UNUSED && (isOptionDisabled?.(option) ?? (option._disabled || option._visible))) {
      handleOptionSelect(option, true);
    } else {
      option._position = position;
      handleOptionSelect(option, false);
    }
  };

  const composeOptionLabel = (option: MetadataOption) => {
    if (
      option.data.type === Metadata.SYSTEM_LABEL &&
      option.data.label !== option.data.key &&
      option.data.subType !== Metadata.EKS_LABEL
    ) {
      return `${option.data.key} (${option.data.label})`;
    } else {
      return option?.data?.label ?? "";
    }
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: MetadataOption) => {
    const label = option.selectedValue ? option.selectedValue : composeOptionLabel(option);

    const transform = transforms[option.id];
    const displayLabel = transform ? (transform(label) ?? label) : label;

    const matches = match(displayLabel, inputValue, { insideWords: true });
    const parts = parse(displayLabel, matches);

    return (
      <Box
        component="li"
        {...props}
        key={`${option.data.key}-${option.data.label}-${option.selectedValue ?? ""}`}
        sx={(theme) => ({
          "&.MuiAutocomplete-option.Mui-focused": {
            background: "none",
            "&:hover": {
              background: theme.palette.action.hover,
            },
          },
        })}
      >
        {option.selectedValue ? (
          <Typography
            variant="body2"
            title={composeOptionLabel(option)}
            sx={{
              pl: 0.8,
            }}
          >
            <Typography
              component="div"
              sx={{
                pr: 3,
                maxWidth: "370px",
                overflowWrap: "break-word",
              }}
            >
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 600 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Typography>
          </Typography>
        ) : (
          <Typography
            title={composeOptionLabel(option)}
            sx={{
              maxWidth: "370px",
              pr: 3,
              overflowWrap: "break-word",
            }}
          >
            {composeOptionLabel(option)}
          </Typography>
        )}
      </Box>
    );
  };

  const getOptionLabel = (option: MetadataOption | string) => {
    if (typeof option !== "string") {
      return composeOptionLabel(option);
    }
    return option;
  };

  const onChange = (_, value: MetadataOption | string | null) => {
    if (value && typeof value !== "string") {
      handleClickRow(value);
    }
  };

  const selectSingleSection = useCallback(
    (name: string) => () => {
      setSelectedSections(() => [name]);
    },
    []
  );

  const selectAllSections = useCallback(
    () => () => {
      setSelectedSections(() => sections.map((section) => section.name));
    },
    [sections]
  );

  const optionsBySelectedSection = useMemo(
    () => allOptions.filter((option) => selectedSections.includes(option.typeLabel ?? "")),
    [allOptions, selectedSections]
  );

  const prevFilterValues = useRef<MetadataOption[]>([]);
  const debouncedInput = useDebounce(inputValue);

  const filterOptionsRegular = createFilterOptions<MetadataOption>(
    {
      trim: true,
      ignoreAccents: true,
      ignoreCase: true,
      matchFrom: "any",
    },
    true,
    position,
    inputValue === ""
  );

  const filterOptionsWithValues = useCallback(
    (
      options: MetadataOption[],
      {
        inputValue,
        getOptionLabel,
      }: {
        inputValue: string;
        getOptionLabel: (option: MetadataOption) => string;
      }
    ) => {
      const valueToSearch = inputValue.toLowerCase().trim();
      if (!inputValue) {
        return options;
      }

      if (inputValue !== debouncedInput) {
        return prevFilterValues.current;
      }

      // first find all match first level options
      const foundOptions = filterOptionsRegular(options, { inputValue, getOptionLabel });

      // Sort options prioritizing exact matches
      const exactMatches = foundOptions.filter((option): option is MetadataOption => {
        const optionLabel = getOptionLabel(option).toLowerCase();

        // First check the label
        if (optionLabel === valueToSearch) {
          return true;
        }

        // Then check values if they exist
        if (option.data.values && Array.isArray(option.data.values)) {
          const transform = transforms[option.id];
          for (const value of option.data.values) {
            const valueToCheck = transform ? (transform(value) ?? value) : value;
            if (valueToCheck.toLowerCase() === valueToSearch) {
              return true;
            }
          }
        }

        return false;
      });

      // Ensure partial matches excludes anything that was an exact match
      const exactMatchIds = new Set(exactMatches.map((opt) => opt.id));
      const foundOptionsSet = new Set<MetadataOption>(foundOptions);

      // Preserve original order while prioritizing exact matches
      const optionsWithNewOrder = [...exactMatches];
      options.forEach((option) => {
        if (!exactMatchIds.has(option.id)) {
          optionsWithNewOrder.push(option);
        }
      });

      const resultOptions = optionsWithNewOrder.flatMap((option: MetadataOption) => {
        const result: MetadataOption[] = [];
        const transform = transforms[option.id];

        let optionAdded = false;
        if (foundOptionsSet.has(option)) {
          result.push(option);
          optionAdded = true;
        }

        // check for values in the option
        if (
          valueToSearch.length > 1 &&
          option.data.values &&
          Array.isArray(option.data.values) &&
          option.data.values.length > 0
        ) {
          const filteredValues: string[] = [];
          const limitResults = inputValue.length < 4 ? 2 : 5;

          for (const value of option.data.values) {
            if (filteredValues.length > limitResults) {
              break;
            }
            const valueToSearch = transform ? (transform(value) ?? value) : value;
            const containsValue = valueToSearch.toLowerCase().includes(inputValue.toLowerCase());
            if (containsValue) {
              filteredValues.push(value);
            }
          }

          if (filteredValues.length > 0 && !optionAdded) {
            result.push(option);
          }

          const valuesToInsert = filteredValues.map((value) => ({
            ...option,
            selectedValue: value,
          }));

          result.push(...valuesToInsert);
        }
        return result;
      });

      // save the result for the next debounce
      prevFilterValues.current = resultOptions;

      return resultOptions;
    },
    [filterOptionsRegular, transforms, debouncedInput]
  );

  return (
    <Stack direction="row" sx={{ height: "100%" }}>
      <Box
        sx={{ borderLeft: 1, borderColor: "general.divider", width: "45%", overflowY: "auto" }}
        data-cy="filter-popover-left"
      >
        <List>
          <FilterSectionItem
            name={reportTxt.ALL_SECTIONS}
            bold={selectedSections.length === sections.length}
            handleSelectSection={selectAllSections}
          />
          {sections.map((section) => (
            <FilterSectionItem
              bold={selectedSections.includes(section.name) && selectedSections.length !== sections.length}
              key={section.name}
              name={section.name}
              handleSelectSection={selectSingleSection}
            />
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ flexGrow: 1 }} data-cy="filter-popover-right">
        <Autocomplete
          data-cy="filter-popover-input"
          sx={{
            pl: 1,
            pr: 1,
            pt: "2px",
          }}
          disablePortal
          autoHighlight
          filterSelectedOptions={true}
          freeSolo
          fullWidth
          inputValue={inputValue}
          value={val}
          onInputChange={(_, value, reason) => {
            handleInputChange(value, reason);
          }}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getOptionLabel}
          groupBy={(option) => option.typeLabel ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={onChange}
          open
          options={optionsBySelectedSection}
          popupIcon={null}
          renderOption={renderOption}
          filterOptions={showValuesInFilterSearchResults ? filterOptionsWithValues : filterOptionsRegular}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <Stack>
              <TextField
                {...params}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    onClose();
                  }
                }}
                placeholder={
                  showValuesInFilterSearchResults ? "Search for specific Labels, Services, SKUs etc." : "Type to search"
                }
                variant="outlined"
                margin="dense"
                size="small"
                autoFocus
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ ml: 0.5 }}
                    size="small"
                    checked={showValuesInFilterSearchResults}
                    onChange={(event) => {
                      handleShowValuesChange(event.target.checked);
                    }}
                  />
                }
                label={
                  <>
                    <Typography variant="caption" sx={{ ml: -0.5 }}>
                      {reportTxt.INCLUDE_LABELS}
                      <Tooltip title={reportTxt.ALLOW_VALUES_IN_SEARCH_TOOLTIP}>
                        <InfoOutlinedIcon
                          htmlColor={grey[600]}
                          sx={{ fontSize: "16px", position: "relative", top: "3px", left: "3px" }}
                        />
                      </Tooltip>
                    </Typography>
                  </>
                }
              />
            </Stack>
          )}
          slots={{
            paper: PaperComponent,
            popper: (props) => (
              <Box {...props}>
                <>{props.children as ReactNode}</>
              </Box>
            ),
          }}
          slotProps={{
            listbox: {
              style: {
                maxHeight: "345px",
                overflow: "visible",
              },
            },
            popper: { style: { width: 327 } },
          }}
        />
      </Box>
    </Stack>
  );
};

export const FilterPopover = (props: Omit<Props, "open"> & { anchorEl: PopoverProps["anchorEl"] }) => {
  const { anchorEl, onClose } = props;
  const open = Boolean(anchorEl);

  return (
    <Popover
      data-cy="filter-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Card sx={{ height: "400px", width: "600px" }}>
        <FilterContent open={true} {...props} />
      </Card>
    </Popover>
  );
};

export default FilterContent;
