import { type Dispatch, useCallback, useEffect, useState } from "react";

import {
  type ApiDescriptor,
  type ApiServiceBaseOperationDescriptor,
  type ApiServiceDescriptor,
  CloudflowEngine,
} from "@doitintl/cmp-models";
import { getCollection, type TransformMethod, useDocumentData } from "@doitintl/models-firestore";
import { type FirebaseCollectionReferenceModel } from "@doitintl/models-firestore/src/core";
import { type DocumentData } from "firebase/firestore";
import noop from "lodash/noop";

import { type OnOperationSelectFn } from "../ActionSearchDialog";
import { popularServices } from "../consts";
import { type Operation, useOperationsQuery } from "./useOperationsQuery";
import { usePaginatedCollectionData } from "./usePaginatedCollectionData";

type ActiveQuery<T extends DocumentData = DocumentData> = [
  query: FirebaseCollectionReferenceModel<T> | undefined,
  transform: TransformMethod<T, ListItem>,
  options: { queryField: string & keyof T; transformedQueryField?: string & keyof T; popularItems?: string[] },
];

export type ListItem = {
  itemId: string;
  key: string;
  label: string;
  caption: string | undefined;
  providerId: string;
  providerName: string;
  serviceName: string;
  serviceId: string;
  version: string;
};

export type ProviderFilter = { id: string; name: string };

export type ServiceFilter = { id: string; name: string; version: string };

const transformProviders: TransformMethod<ApiDescriptor, ProviderFilter> = (
  { providerName, providerShortName, description },
  { id }
) => ({
  id,
  name: providerShortName,
  key: `${providerName}-${id}`,
  label: providerName,
  providerId: id,
  caption: description,
});

const transformOperations: TransformMethod<ApiServiceBaseOperationDescriptor, ListItem> = (
  { operationName, description, providerId, providerName, serviceId, serviceName, version },
  { id }
) => ({
  key: `${providerId}-${serviceId}-${operationName}-${id}`,
  itemId: id,
  label: operationName,
  caption: description,
  providerId,
  providerName,
  serviceId,
  serviceName,
  version,
});

const transformQueryResults = ({
  operationName,
  description,
  providerId,
  providerName,
  serviceId,
  serviceName,
  version,
}: Operation): ListItem => ({
  key: `${providerId}-${serviceId}-${operationName}`,
  itemId: operationName,
  label: operationName,
  caption: description,
  providerId,
  providerName,
  serviceName,
  serviceId,
  version,
});

const providersQuery = getCollection(CloudflowEngine).doc("integrations").collection("apis");

export function useActionSearchDialogStateMachine(
  onOperationSelect: OnOperationSelectFn,
  provider: ProviderFilter | undefined,
  isProviderLoading: boolean
) {
  const [query, setQuery] = useState("");
  const [items, setItems] = useState<ListItem[]>([]);
  const [service, setService] = useState<ServiceFilter>();

  const showingQueryResults = query.trim() !== "";

  const goBackToServices = useCallback(() => {
    setService(undefined);
    setQuery("");
  }, []);

  const transformServices = useCallback<TransformMethod<ApiServiceDescriptor, ListItem>>(
    ({ serviceName, latestVersion, description, documentation }, { id }) => ({
      key: `${provider?.id}-${serviceName}-${id}`,
      itemId: serviceName,
      label: serviceName,
      caption: description || documentation, // NOSONAR
      providerId: provider?.id ?? "",
      providerName: provider?.name ?? "",
      serviceId: id,
      serviceName,
      version: latestVersion,
    }),
    [provider?.id, provider?.name]
  );

  let browsedCollection: ActiveQuery;
  let onListItemClick: Dispatch<ListItem>;

  switch (true) {
    case showingQueryResults:
      browsedCollection = [undefined, noop as TransformMethod<DocumentData, ListItem>, { queryField: "" }];
      onListItemClick = ({ itemId, providerId, serviceId, version }) => {
        onOperationSelect({ operationName: itemId, provider: providerId, service: serviceId, version });
      };
      break;
    case provider !== undefined && service !== undefined:
      browsedCollection = [
        providersQuery
          .doc(provider.id)
          .collection("services")
          .doc(service.id)
          .collection("versions")
          .doc(service.version)
          .collection("operations"),
        transformOperations as TransformMethod<DocumentData, ListItem>,
        {
          queryField: "operationName",
          transformedQueryField: "itemId",
        },
      ];
      onListItemClick = ({ itemId, providerId, serviceId, version }) => {
        onOperationSelect({ operationName: itemId, provider: providerId, service: serviceId, version });
      };
      break;
    case provider !== undefined:
      browsedCollection = [
        providersQuery.doc(provider.id).collection("services"),
        transformServices as TransformMethod<DocumentData, ListItem>,
        { queryField: "serviceName", popularItems: popularServices[provider.id] },
      ];
      onListItemClick = ({ serviceId, serviceName, version }) => {
        setService({ id: serviceId, name: serviceName, version });
      };
      break;
    case provider === undefined && isProviderLoading:
      browsedCollection = [undefined, noop as TransformMethod<DocumentData, ListItem>, { queryField: "" }];
      onListItemClick = noop;
      break;
    default:
      throw new Error("unknown state");
  }

  const [queryResults, loadingQuery] = useOperationsQuery(
    transformQueryResults,
    query.trim(),
    provider?.id,
    service?.id
  );

  const [browseItems, popularItems, loadingBrowseItems, loadPrevPage, loadNextPage, disablePrevPage, disableNextPage] =
    usePaginatedCollectionData(...browsedCollection);

  const isLoading = (showingQueryResults && loadingQuery) || (!showingQueryResults && loadingBrowseItems);

  useEffect(() => {
    if (showingQueryResults && !loadingQuery && queryResults) {
      setItems(queryResults);
    } else if (!showingQueryResults && !loadingBrowseItems && browseItems) {
      setItems(browseItems);
    } else {
      setItems([]);
    }
  }, [browseItems, loadingBrowseItems, loadingQuery, queryResults, showingQueryResults]);

  return {
    query,
    setQuery,
    isLoading,
    items,
    popularItems,
    loadPrevPage,
    loadNextPage,
    disablePrevPage,
    disableNextPage,
    onListItemClick,
    provider,
    service,
    goBackToServices,
    showingQueryResults,
    setService,
  };
}

export function useProviderData(providerId: string) {
  return useDocumentData(providersQuery.doc(providerId), {
    transform: transformProviders,
  });
}
