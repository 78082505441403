import { getModelByPath } from "@doitintl/cloudflow-commons/src/model-descriptors";
import {
  DATE_TIME_DURATIONS,
  DATE_TIME_FORMATS,
  type DateTimeDuration,
  type DateTimeFormatOption,
  type DateTimeTransformations,
  ModelType,
} from "@doitintl/cmp-models";
import * as yup from "yup";

import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";

export type DateTimeSchema = {
  referencedNodeField: {
    referencedNodeId: string;
    referencedField: string[];
  };
  newFieldName: string;
  type: DateTimeTransformations["type"];
  value: number;
  duration: DateTimeDuration;
  format: DateTimeFormatOption;
};

export function generateDateTimeTransformSchema(referenceableNodes: NodeWitOutputModel[]) {
  return yup.object({
    referencedNodeField: yup
      .object({
        referencedNodeId: yup.string().required().default(""),
        referencedField: yup.array().of(yup.string().required()).default([]).required(),
      })
      .test("referenced-field-model", "Referenced field must reference a field with type timestamp", (value) => {
        const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
        if (referencedNodeModel === undefined) {
          return false;
        }
        const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
        return referencedModel.type === ModelType.TIMESTAMP;
      }),
    type: yup.string().default("add").required().label("Transform action"),
    newFieldName: yup.string().trim().default("").min(1).required(),

    // conditional type for time add and sub
    value: yup
      .number()
      .default(1)
      .when("type", {
        is: (type: string) => type === "add" || type === "subtract",
        then: (schema) =>
          schema
            .required("number required")
            .integer()
            .typeError("invalid number")
            .min(1, "Must be greater than 0")
            .default(1),
        otherwise: (schema) => schema,
      }),

    duration: yup
      .string()
      .oneOf(DATE_TIME_DURATIONS)
      .default("minutes")
      .when("type", {
        is: (type: string) => type === "add" || type === "subtract",
        then: (schema) => schema.required(),
      }),

    // conditional type for time format
    format: yup
      .string()
      .oneOf(DATE_TIME_FORMATS)
      .default("DD/MM/YYYY")
      .when("type", {
        is: "format",
        then: (schema) => schema.required(),
      }),
  });
}
