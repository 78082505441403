import { CloudFlowNodeType } from "@doitintl/cmp-models";

import DisablePublishedFlowGuard from "../../Common/DisablePublishedFlowGuard";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import APIParametersTab from "./APIParametersTab";
import { ConditionParametersTab } from "./ConditionParametersTab";
import DateTimeTransformParametersTab from "./DateTimeTransformParametersTab";
import FilterParametersTab from "./FilterParametersTab";
import { ManualTriggerTab } from "./ManualTriggerTab";
import PermissionsTab from "./PermissionsTab";
import ScheduleTab from "./ScheduleTab";
import TestsTab from "./TestsTab";
import TransformParametersTab from "./TransformParametersTab";

const ActionParams = () => {
  const { activeTab } = useNodeConfigurationContext();
  switch (activeTab) {
    case "Permissions":
      return <PermissionsTab />;
    case "Test":
      return <TestsTab />;
    default:
      return <APIParametersTab />;
  }
};

export const TabContent = () => {
  const { nodeConfig } = useNodeConfigurationContext();

  switch (nodeConfig.type) {
    case CloudFlowNodeType.ACTION:
      return <ActionParams />;
    case CloudFlowNodeType.CONDITION:
      return (
        <DisablePublishedFlowGuard>
          <ConditionParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return (
        <DisablePublishedFlowGuard>
          <ManualTriggerTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.FILTER:
      return (
        <DisablePublishedFlowGuard>
          <FilterParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.TRANSFORMATION:
      return (
        <DisablePublishedFlowGuard>
          <TransformParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.DATETIME_TRANSFORM:
      return (
        <DisablePublishedFlowGuard>
          <DateTimeTransformParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.TRIGGER:
      return (
        <DisablePublishedFlowGuard>
          <ScheduleTab />
        </DisablePublishedFlowGuard>
      );
  }

  return null;
};
