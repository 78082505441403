import { Stack, Typography } from "@mui/material";

export const EmptyState = () => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    <Typography variant="subtitle2">No results found</Typography>
    <Typography variant="body2" color="text.secondary">
      Try a different term or browse for an action
    </Typography>
  </Stack>
);
