import { useMemo, useState } from "react";

import { popularServices } from "../consts";
import { type ListItem, type ProviderFilter, type ServiceFilter } from "./useActionSearchDialogStateMachine";

export type ServiceBrowsingTab = "popular" | "all";

export function useServiceBrowsing(
  provider: ProviderFilter | undefined,
  service: ServiceFilter | undefined,
  items: ListItem[],
  popularItems: ListItem[] | undefined,
  isSearching: boolean,
  isProviderLoading: boolean
) {
  const [activeTab, setActiveTab] = useState<ServiceBrowsingTab>("popular");

  return useMemo(() => {
    // Only show tabs when we have a provider and popular services for it
    const showTabs = Boolean(provider && !isSearching && !service && popularServices[provider.id]);

    // Show navigation when service is selected or search is active
    const showNavigation = Boolean(provider && (service || isSearching));

    // Show popular items when on popular tab and they exist
    const hasPopularItems =
      provider && popularServices[provider.id] && activeTab === "popular" && popularItems && popularItems.length > 0;

    // Show pagination only when browsing all services
    const showPagination = Boolean(provider && !isSearching && !isProviderLoading && activeTab === "all");

    return {
      displayItems: hasPopularItems ? popularItems : items,
      showTabs,
      showNavigation,
      showPagination,
      activeTab,
      setActiveTab,
    };
  }, [provider, isSearching, service, activeTab, popularItems, isProviderLoading, items]);
}
