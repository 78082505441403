import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useApiContext } from "@client/src/api/context";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { consoleErrorWithSentry } from "@client/src/utils";
import { useFullScreen } from "@client/src/utils/dialog";
import useSegmentTrackEvent from "@client/src/utils/useSegmentTrackEvent";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { type SxProps } from "@mui/system";

import cdAppSettings from "../cdAppSetting";

interface DiagramData {
  scheme_id: string;
  statussheet_id: string;
}

interface SchemeResponse {
  scheme?: {
    [key: string]: {
      statussheet?: Array<{ ssid: string }>;
    };
  };
}

interface DashboardHeadProps {
  onSearch: (query: string) => void;
}

const NetworkDiagramButton = ({
  sx,
  disabled,
  ...props
}: {
  sx?: SxProps;
  disabled?: boolean;
  [key: string]: unknown;
}) => {
  const [networkDiagram, setNetworkDiagram] = useState<DiagramData | undefined>();

  const { customer } = useCustomerContext();
  const api = useApiContext();

  useEffect(() => {
    const fetchNetworkDiagram = async () => {
      if (!customer?.id) {
        consoleErrorWithSentry("Missing customer");
        return;
      }

      try {
        const { data } = await api.request<SchemeResponse>({
          method: "post",
          baseURL: cdAppSettings.baseUrl,
          url: "/cloud-diagrams/api/scheme/get",
          params: {
            customer: customer.id,
            "type[]": "network",
          },
        });

        const scheme_id = Object.keys(data?.scheme || {})[0];
        const statussheet_id = data?.scheme?.[scheme_id]?.statussheet?.[0]?.ssid;

        if (scheme_id && statussheet_id) {
          setNetworkDiagram({ scheme_id, statussheet_id });
        }
      } catch (err) {
        consoleErrorWithSentry(err);
      }
    };

    fetchNetworkDiagram();
  }, [api, customer]);

  return (
    <Button
      component="a"
      variant="outlined"
      color="primary"
      sx={{ height: 40, ...sx }}
      disabled={!!disabled || !networkDiagram}
      href={
        networkDiagram
          ? `/cloud-diagrams/scheme/${networkDiagram.scheme_id}/${networkDiagram.statussheet_id}/${customer?.id}`
          : "#"
      }
      size="large"
      data-cy="clouddiagrams-view-network-layer"
      {...props}
    >
      View network layer
    </Button>
  );
};

let debounceTimer: NodeJS.Timeout;

const DashboardHead: React.FC<DashboardHeadProps> = ({ onSearch }) => {
  const { customer } = useCustomerContext();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { trackEvent } = useSegmentTrackEvent();
  const { isMobile } = useFullScreen();

  const handleCreateDiagramClick = () => {
    trackEvent("Clouddiagrams Create Diagram Clicked", {
      pageType: "Governance",
      pageSubType: "CloudDiagrams",
      feature: "CloudDiagrams",
      isPageOpenEvent: false,
    });
  };

  useEffect(() => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      onSearch(searchQuery.trim().toLowerCase());
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [onSearch, searchQuery]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid sx={{ paddingTop: "1rem" }}>
        <Typography
          data-cy="clouddiagrams-title"
          variant="h1"
          sx={{
            mb: 1,
          }}
        >
          Cloud diagrams
        </Typography>
        <Typography
          data-cy="clouddiagrams-description"
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 400,
            mb: 2,
          }}
        >
          Visualize and analyze your cloud infrastructure
        </Typography>
      </Grid>
      <Grid
        container
        sx={
          isMobile
            ? { width: "100%", flexDirection: "column-reverse", gap: 3, mb: 1.5 }
            : {
                alignItems: "center",
                gap: 1,
              }
        }
      >
        <TextField
          data-cy="clouddiagrams-dashboard-search"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Search diagrams"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
        />
        {isMobile && (
          <Alert severity="info">Cloud Diagrams is unavailable on mobile screens. Please use a desktop device.</Alert>
        )}
        <Box sx={isMobile ? { display: "flex", gap: 2 } : undefined}>
          <NetworkDiagramButton
            disabled={isMobile}
            sx={isMobile ? { marginLeft: 0, flex: "1 1 0", minWidth: "10px" } : { marginLeft: 0.5 }}
          />
          <Button
            data-cy="clouddiagrams-dashboard-creatediagram"
            variant="contained"
            color="primary"
            disabled={isMobile}
            sx={isMobile ? { flex: "1 1 0", minWidth: "10px" } : { marginLeft: 1.5 }}
            component={Link}
            to={`/customers/${customer.id}/cloud-diagrams/onboarding`}
            size="large"
            onClick={handleCreateDiagramClick}
          >
            Create diagram
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardHead;
