export const popularServices = {
  AWS: [
    "AWS Identity and Access Management",
    "AWS CloudFormation",
    "Amazon Elastic Compute Cloud",
    "AWS Lambda",
    "Amazon Simple Storage Service",
  ],
  GCP: ["compute", "storage", "gkehub", "bigquery", "sqladmin"],
};
