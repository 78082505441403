import { CloudFlowProvider } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, List, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useModalManager } from "../Common/CloudflowModalsProvider";
import { ActionSearchItem } from "./ActionSearchItem";
import { EmptyState } from "./EmptyState";
import { useActionSearchDialogStateMachine, useProviderData } from "./hooks/useActionSearchDialogStateMachine";
import { useServiceBrowsing } from "./hooks/useServiceBrowsing";
import { PaginationControls } from "./PaginationControls";
import { SearchHeader } from "./SearchHeader";
import { ServiceNavigation } from "./ServiceNavigation";

export type OnOperationSelectFn = (operation: {
  operationName: string;
  provider: string;
  service: string;
  version: string;
}) => void;

export const ActionSearchDialog = ({
  onOperationSelect,
  selectedProvider,
}: {
  onOperationSelect: OnOperationSelectFn;
  selectedProvider: CloudFlowProvider;
}) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const { closeModal } = useModalManager();

  const [provider, providerLoading] = useProviderData(selectedProvider);

  const {
    query,
    setQuery,
    isLoading,
    items,
    popularItems,
    disableNextPage,
    disablePrevPage,
    loadNextPage,
    loadPrevPage,
    onListItemClick,
    service,
    goBackToServices,
    setService,
    showingQueryResults,
  } = useActionSearchDialogStateMachine(onOperationSelect, provider, providerLoading);

  const { displayItems, showTabs, showNavigation, showPagination, activeTab, setActiveTab } = useServiceBrowsing(
    provider,
    service,
    items,
    popularItems,
    showingQueryResults,
    providerLoading
  );

  const isLoadingState = providerLoading || isLoading;

  return (
    <Dialog
      open
      onClose={() => {
        closeModal("action");
      }}
      fullScreen={fullScreen}
      fullWidth
      slotProps={{ paper: { sx: { maxWidth: 704, height: 704 } } }}
    >
      <DialogTitle>{`Find ${selectedProvider === CloudFlowProvider.GCP ? "Google Cloud" : selectedProvider} actions`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal("action");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <SearchHeader query={query} setQuery={setQuery} isLoading={isLoadingState} disabled={providerLoading} />
      <DialogContent
        sx={{
          backgroundColor: "general.backgroundDark",
          py: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignSelf: "stretch",
        }}
        dividers
      >
        {provider && showNavigation && (
          <ServiceNavigation
            provider={provider}
            service={service}
            onServiceChange={setService}
            onBackClick={goBackToServices}
          />
        )}
        {showTabs && (
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => {
              setActiveTab(newValue);
            }}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab sx={{ textTransform: "none" }} label="Popular services" value="popular" />
            <Tab sx={{ textTransform: "none" }} label="All services" value="all" />
          </Tabs>
        )}
        {!isLoadingState &&
          (displayItems.length === 0 ? (
            <EmptyState />
          ) : (
            <List sx={{ flex: 1 }}>
              {displayItems.map((item) => (
                <ActionSearchItem key={item.key} item={item} onClick={onListItemClick} />
              ))}
            </List>
          ))}
      </DialogContent>
      {showPagination && (
        <PaginationControls
          onPrevPage={loadPrevPage}
          onNextPage={loadNextPage}
          disablePrevPage={disablePrevPage}
          disableNextPage={disableNextPage}
        />
      )}
    </Dialog>
  );
};
