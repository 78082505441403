import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DialogActions, IconButton } from "@mui/material";

type PaginationControlsProps = {
  onPrevPage: () => void;
  onNextPage: () => void;
  disablePrevPage: boolean;
  disableNextPage: boolean;
};

export const PaginationControls = ({
  onPrevPage,
  onNextPage,
  disablePrevPage,
  disableNextPage,
}: PaginationControlsProps) => (
  <DialogActions sx={{ py: "1px", pr: 2 }}>
    <IconButton onClick={onPrevPage} disabled={disablePrevPage} size="small">
      <ChevronLeftIcon />
    </IconButton>
    <IconButton onClick={onNextPage} disabled={disableNextPage} size="small">
      <ChevronRightIcon />
    </IconButton>
  </DialogActions>
);
