import React from "react";

import {
  AnomalousOther,
  type AnomalyFeedbackReason,
  ExpectedCostSpike,
  FaultyAnomalyDetectionModel,
  IncorrectData,
  LowImpactAnomaly,
  Misconfiguration,
  NonAnomalousOther,
  SecurityBreach,
  UnintentionalProvisioning,
} from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent, type SxProps } from "@mui/material";
import type { Theme } from "@mui/system";

import { anomalyFeedbackReasonsLabels } from "./consts";

type Props = {
  value?: AnomalyFeedbackReason;
  onChange: (value: string) => void;
  isAnomaly?: boolean;
  sx?: SxProps<Theme>;
};

const AnomalyFeedbackReasonSelect = ({ value, onChange, isAnomaly, sx }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size="small" required sx={sx}>
      <InputLabel id="anomalous-reason-select-label">Reason</InputLabel>
      <Select
        labelId="anomalous-reason-select-label"
        value={value ?? ""}
        data-cy="anomalous-reason-select"
        label="Reason"
        onChange={handleChange}
      >
        {isAnomaly && [
          <MenuItem value={SecurityBreach} key={SecurityBreach}>
            {anomalyFeedbackReasonsLabels[SecurityBreach]}
          </MenuItem>,
          <MenuItem value={Misconfiguration} key={Misconfiguration}>
            {anomalyFeedbackReasonsLabels[Misconfiguration]}
          </MenuItem>,
          <MenuItem value={UnintentionalProvisioning} key={UnintentionalProvisioning}>
            {anomalyFeedbackReasonsLabels[UnintentionalProvisioning]}
          </MenuItem>,
          <MenuItem value={ExpectedCostSpike} key={ExpectedCostSpike}>
            {anomalyFeedbackReasonsLabels[ExpectedCostSpike]}
          </MenuItem>,
          <MenuItem value={AnomalousOther} key={AnomalousOther}>
            {anomalyFeedbackReasonsLabels[AnomalousOther]}
          </MenuItem>,
        ]}

        {!isAnomaly && [
          <MenuItem value={FaultyAnomalyDetectionModel} key={FaultyAnomalyDetectionModel}>
            {anomalyFeedbackReasonsLabels[FaultyAnomalyDetectionModel]}
          </MenuItem>,
          <MenuItem value={IncorrectData} key={IncorrectData}>
            {anomalyFeedbackReasonsLabels[IncorrectData]}
          </MenuItem>,
          <MenuItem value={LowImpactAnomaly} key={LowImpactAnomaly}>
            {anomalyFeedbackReasonsLabels[LowImpactAnomaly]}
          </MenuItem>,
          <MenuItem value={NonAnomalousOther} key={NonAnomalousOther}>
            {anomalyFeedbackReasonsLabels[NonAnomalousOther]}
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
};

export default AnomalyFeedbackReasonSelect;
