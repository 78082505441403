import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { IconButton } from "@mui/material";

import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";

type Props = {
  handleOpen: () => void;
};

export const AvaRoundButton = ({ handleOpen }: Props) => {
  const darkMode = useDarkThemeCheck();

  return (
    <IconButton
      onClick={handleOpen}
      sx={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "primary.main",
        color: darkMode ? "primary.contrastText" : "navigation.text",
        borderRadius: "50%",
        padding: 1.5,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        zIndex: 1001,
        "&:hover": {
          backgroundColor: "primary.dark",
        },
      }}
    >
      <AutoAwesomeIcon />
    </IconButton>
  );
};
