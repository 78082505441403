import { type Widget } from "../Context/useCurrentDashboardContext";

// parseWidgetDocId is used to parse the widget doc id into customerId, orgId and reportId
// The widget doc id can be "<CUSTOMERID>_<REPORTID>" or "<CUSTOMERID>_<ORGID>_<REPORTID>"
// If there is no ORGID, it will return "root" as orgId instead.
export const parseWidgetDocId = (widgetDocId: string): { customerId: string; orgId: string; reportId: string } => {
  const values = widgetDocId.split("_");

  if (values.length === 2) {
    return { customerId: values[0], orgId: "root", reportId: values[1] };
  }

  return { customerId: values[0], orgId: values[1], reportId: values[2] };
};

// getWidgetDocId is used to get the widget doc id from the customerId, reportId and orgId
// If orgId is not null, it will return "<CUSTOMERID>_<ORGID>_<REPORTID>"
// Otherwise, it will return "<CUSTOMERID>_<REPORTID>"
export const getWidgetDocId = (customerId: string, reportId: string, orgId: string | null): string => {
  if (orgId && orgId !== "root") {
    return `${customerId}_${orgId}_${reportId}`;
  }

  return `${customerId}_${reportId}`;
};

export const filterExcludeWidgetsIfStandaloneCustomer = (
  widgetName: string,
  isProductOnlyCustomer: boolean
): boolean => {
  if (!isProductOnlyCustomer) {
    return true;
  }

  const standaloneCustomersCantUseTheseWidgetsByName = ["entities"];
  return !standaloneCustomersCantUseTheseWidgetsByName.includes(widgetName);
};

export const isTemplateWidget = (widget: Widget) => widget.name.includes("::");

export const getWidgetBaseName = (widget: Widget) =>
  isTemplateWidget(widget) ? widget.name.substring(0, widget.name.indexOf("::")) : widget.name;

export const getWidgetId = (widget: Widget) =>
  isTemplateWidget(widget) ? widget.name.substring(widget.name.lastIndexOf(":") + 1) : widget.name;
