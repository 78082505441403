import { useQuery } from "@tanstack/react-query";
import { CanceledError, isAxiosError } from "axios";

import { useApiContext } from "../../../../../api/context";
import { useErrorSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useDebounce } from "../../../../../utils/useDebounce";

export type Operation = {
  operationName: string;
  providerName: string;
  providerId: string;
  serviceName: string;
  serviceId: string;
  version: string;
  documentation?: string;
  description?: string;
};

export function useOperationsQuery<TOutput>(
  transform: (operation: Operation) => TOutput,
  query: string,
  provider?: string,
  service?: string
) {
  const { customer } = useCustomerContext();
  const showErrorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const trimmedQuery = useDebounce(query.trim(), 250);

  const { data, isLoading } = useQuery({
    enabled: trimmedQuery !== "",
    queryKey: [trimmedQuery, provider, service],
    queryFn: async ({ signal }) => {
      try {
        const { data } = await api.request<Operation[] | null>({
          method: "get",
          url: `/v1/customers/${customer.id}/cloudflow/operations`,
          params: {
            query: trimmedQuery,
            provider,
            service,
          },
          signal,
        });
        return data?.map(transform) ?? [];
      } catch (exception) {
        if (!(exception instanceof CanceledError)) {
          consoleErrorWithSentry(exception);
          let errorMessage = "Unknown error. Please try again.";
          if (isAxiosError(exception)) {
            errorMessage = JSON.stringify(exception.response?.data);
          } else if (exception instanceof Error) {
            errorMessage = exception.message;
          }
          showErrorSnackbar(errorMessage);
        }
      }
    },
  });

  return [data, isLoading] as const;
}
