import { consoleErrorWithSentry } from "@client/src/utils";

import cdAppSettings from "./cdAppSetting";

const importAWSAccountCD = async ({ api, account, customer, setIsImporting, handleCancel, onError = () => {} }) => {
  if (!account?.accountId || !customer?.id) {
    consoleErrorWithSentry("Wrong account or customer");
    onError();
    return;
  }

  setIsImporting(true);

  try {
    let { data } = await api.request({
      method: "post",
      baseURL: cdAppSettings.baseUrl,
      url: "/cloud-diagrams/api/scheme/account-import",
      params: {
        customer: customer.id,
      },
      data: {
        account: account.accountId,
        customer: customer.id,
      },
    });

    if (!data?._id || !data?.statussheet?.[0]?.ssid) {
      consoleErrorWithSentry("Something went wrong");
      onError();
      return;
    }

    let newUrl = `/cloud-diagrams/scheme/${data._id}/${data.statussheet[0].ssid}/${customer.id}`;
    window.open(newUrl);

    setIsImporting(false);
    handleCancel();
  } catch (err) {
    consoleErrorWithSentry(err);
    setIsImporting(false);
    handleCancel();
    onError();
  }
};

export { importAWSAccountCD };
export default importAWSAccountCD;
